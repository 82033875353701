/* eslint-disable no-unused-vars */
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { themeSettings } from 'theme';
import Generate from 'scenes/generate';
import Layout from 'scenes/layout';
import Training from 'scenes/training';
import Users from 'scenes/users';
import Reports from 'scenes/reports';
import Login from 'scenes/login';
import RequireAuth from 'scenes/RequireAuth';
import Order from 'scenes/generate/Orders/Order';
import Orders from 'scenes/generate/Orders/Orders';

function App() {
  const mode = useSelector((state) => state.global.mode);
  const isAuthenticated = useSelector(
    (state) => state.authentication.authenticated
  );
  console.log(isAuthenticated);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path=""
              element={
                <Navigate
                  to={isAuthenticated ? '/generate' : '/login'}
                  replace
                />
              }
            />

            {/* <Route path="" element={<Navigate to="/login" replace />} /> */}
            <Route path="login" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route element={<Layout />}>
                <Route path="generate" element={<Generate />} />
                <Route path="orders" element={<Orders />} />
                <Route path="order/:orderId" element={<Order />} />
                <Route path="training" element={<Training />} />
                <Route path="users" element={<Users />} />
                <Route path="reports" element={<Reports />} />
              </Route>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
