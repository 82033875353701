import React from 'react';
import DataGridQueue from './Queue/Datagrid';
import { useDispatch } from 'react-redux';
import { toggleQueueDrawer } from 'state/generate';

const Orders = () => {
  const dispatch = useDispatch();
  return (
    <DataGridQueue setClose={(state) => dispatch(toggleQueueDrawer(state))} />
  );
};

export default Orders;
