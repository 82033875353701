import { TextWrapCellRenderer, ImageCell } from 'components/SmallComponents';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const typesDropdown = (props) => {
  const { item, applyValue, focusElementRef } = props;

  const items = [
    'there is no avatar in the image',
    'the image is broken',
    'no similarity',
    'other issues',
  ];

  const handleFilterChange = (event, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <FormControl className="ring-0">
      <InputLabel id="demo-simple-select-label">{'types'}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={'types'}
        value={item.value}
        label={'types'}
        onChange={(e) => handleFilterChange(e, e.target.value)}
        size="small"
        sx={{ mt: '5px', width: '140px' }}
      >
        {items.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const typeFilterOperator = [
  {
    label: '==',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params) => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: typesDropdown,
    InputComponentProps: { type: 'string' },
    getValueAsString: (value) => `${value}`,
  },
];

export const columns = [
  {
    field: 'reviewed',
    headerName: 'reviewed',
    width: 80,
    type: 'boolean',
  },
  {
    field: 'type',
    headerName: 'type',
    width: 140,
    type: 'string',
    renderCell: TextWrapCellRenderer,
    filterOperators: typeFilterOperator,
  },
  {
    field: 'user',
    headerName: 'user',
    width: 240,
    type: 'string',
  },
  {
    field: 'feedback',
    headerName: 'feedback',
    width: 440,
    renderCell: TextWrapCellRenderer,
  },
  { field: 'product', headerName: 'product', width: 140 },
  { field: 'prompt', headerName: 'prompt', width: 150, editable: false },
  {
    field: 'image',
    headerName: 'image',
    width: 150,
    editable: false,
    renderCell: ImageCell,
  },

  { field: 'lora', headerName: 'lora', width: 300, editable: false },
  {
    field: 'reported_at',
    headerName: 'reported at',
    width: 100,
    editable: false,
    renderCell: TextWrapCellRenderer,
  },
  {
    field: 'generated_at',
    headerName: 'generated at',
    width: 170,
    editable: false,
  },
  { field: 'job', headerName: 'job', width: 300, editable: false, hide: true },
  {
    field: 'order',
    headerName: 'order',
    width: 300,
    editable: false,
    hide: true,
  },
  {
    field: 'index',
    headerName: 'index',
    width: 50,
    editable: false,
    hide: true,
  },
];
