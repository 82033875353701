import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';

import DataGridQueue from './Queue/Datagrid';
import { useSelector, useDispatch } from 'react-redux';
import { isQueueOpen, toggleQueueDrawer } from 'state/generate';

export default function Sidebar() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.generate.isQueueOpen);

  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    dispatch(toggleQueueDrawer(!isOpen));
  };

  return (
    <div>
      <Drawer anchor={'right'} open={isOpen} onClose={toggleDrawer(false)}>
        <DataGridQueue
          setClose={(state) => dispatch(toggleQueueDrawer(state))}
        />
      </Drawer>
    </div>
  );
}
