import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, useTheme } from '@mui/material';

import { useCms } from '../../utils/CMS';
import SmallDialog from 'components/SmallDialog';
import { useDispatch, useSelector } from 'react-redux';
import { setSaveAsData } from 'state/generate';

const SaveAs = ({ open, setOpen, setSaveDialogData }) => {
  const dispatch = useDispatch();
  const saveAsData = useSelector((state) => state.generate.saveAsData);
  const {
    addSubcategory,
    addPrompt,
    updatePrompt,
    getAllCategories,
    getAllGroups,
  } = useCms();

  const [promptName, setPromptName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [newGroupName, setNewGroupName] = useState();

  const [savePromptBlocked, setsavePromptBlocked] = useState(true);

  const [categories, setcategories] = useState([]);
  const [products, setproducts] = useState([]);

  const [isCategorySelected, setisCategorySelected] = useState(false);
  const [info, setinfo] = useState('');

  useEffect(() => {
    if (open) {
      // saveAsData?.name !== '' && setPromptName(saveAsData?.name);
      getAllCategories().then((cats) => {
        setcategories(cats);
        setinfo('');
      });
      setSelectedCategory(saveAsData?.category);
      setSelectedGroup(saveAsData?.product);
    }
  }, [open]);
  // get groups by category
  useEffect(() => {
    if (selectedCategory) {
      setinfo('fetching products');
      getAllGroups().then((groups) => {
        const gs = groups
          .filter((group) => group.parent === selectedCategory)
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        setproducts(gs.map((g) => g.name));
        setisCategorySelected(true);
        setinfo('');
      });
      saveAsData?.product !== '' && setSelectedGroup(saveAsData?.product);
    }
  }, [selectedCategory]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedGroup('');
    dispatch(setSaveAsData({ key: 'product', data: '' }));
    dispatch(setSaveAsData({ key: 'category', data: event.target.value }));
  };
  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
    dispatch(setSaveAsData({ key: 'product', data: event.target.value }));
    setNewGroupName('');
    setNewGroupName('');
    dispatch(setSaveAsData({ key: 'newProduct', data: '' }));
  };
  const handlePromptNameChange = (event) => {
    setPromptName(event.target.value);
    dispatch(setSaveAsData({ key: 'name', data: event.target.value }));
  };
  const handleCreateNewGroup = (event) => {
    setNewGroupName(event.target.value);
    dispatch(setSaveAsData({ key: 'newProduct', data: event.target.value }));
    setSelectedGroup('');
    dispatch(setSaveAsData({ key: 'product', data: '' }));
  };

  return (
    <SmallDialog
      title="Save prompt as"
      content=""
      open={open}
      setOpen={setOpen}
      setData={setSaveDialogData}
      cancelButton="Cancel"
      confirmButton="Save"
      blocked={
        !saveAsData.name ||
        !saveAsData.category ||
        !(saveAsData.product || saveAsData.newProduct)
      }
    >
      <Box
        sx={{
          mt: 1,
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <TextField
          margin="normal"
          id="promptName"
          label="name"
          name="name"
          value={promptName}
          onChange={handlePromptNameChange}
          className="ring-0"
          sx={{ width: '100%' }}
          size="small"
        />
        <FormControl fullWidth className="ring-0">
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="category"
            value={selectedCategory}
            label="category"
            onChange={handleCategoryChange}
            size="small"
            sx={{ mt: '5px' }}
          >
            {categories.map((cat, index) => {
              return (
                <MenuItem key={index} value={cat}>
                  {cat}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {isCategorySelected ? (
          <>
            <FormControl fullWidth className="ring-0">
              <InputLabel id="demo-simple-select-label">Product</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="category"
                value={selectedGroup}
                label="Product"
                onChange={handleGroupChange}
                size="small"
                sx={{ mt: '5px' }}
              >
                {products.map((group, index) => {
                  return (
                    <MenuItem key={index} value={group}>
                      {group}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              margin="normal"
              id="groupName"
              label="create new product"
              name="groupName"
              value={newGroupName}
              onChange={handleCreateNewGroup}
              className="ring-0"
              sx={{ width: '100%' }}
              size="small"
            />
          </>
        ) : (
          info !== '' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
              {info}
            </Box>
          )
        )}
      </Box>
    </SmallDialog>
  );
};

export default SaveAs;
