export const productsEndpoints = (builder) => {
  return {
    getProductImages: builder.mutation({
      query: (name) => ({
        url: `/productImages/${name}`,
        method: 'get',
      }),
    }),
    setProductImages: builder.mutation({
      query: (formData) => {
        const name = formData.get('name');
        return {
          url: `/productImages/${name}`,
          method: 'post',
          body: formData,
        };
      },
    }),
    deleteProductImage: builder.mutation({
      query: (data) => ({
        url: `/productImages/${data.name}?type=${data.type}&filename=${data.filename}`,
        method: 'delete',
      }),
    }),
    modifyImageType: builder.mutation({
      query: (data) => ({
        url: `/modifyThumb/${data.name}/${data.filename}?isThumb=${data.isThumb}`,
        method: 'post',
      }),
    }),
  };
};
