import { createContext, useContext, useState, useEffect, useRef } from 'react';

const GenerateContext = createContext();

export function useGenerate() {
  return useContext(GenerateContext);
}

export function GenerateProvider({ children }) {
  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const promptRef = useRef();
  const npromptRef = useRef();
  const [isLoading, setisLoading] = useState(false);
  const [resultImage, setResultImage] = useState();
  const [resultSeeds, setResultSeeds] = useState();
  const [progress, setProgress] = useState(0);
  const [checkpoints, setcheckpoints] = useState([]);
  const [lorasList, setlorasList] = useState([]);
  const [checkpoint, setCheckpoint] = useState('1_sd_xl_base_1.0');
  const [lora, setLora] = useState('');
  const [loraDialogOpen, setLoraDialogOpen] = useState(false);
  const [selectedLoras, setSelectedLoras] = useState([]);
  const [selectedLorasCount, setselectedLorasCount] = useState({
    male: 0,
    female: 0,
    cat: 0,
    dog: 0,
  });
  const [loraStrength, setLoraStrength] = useState(1);
  const [sdVersion, setSdVersion] = useState('xl');
  const [versionSwitch, setVersionSwitch] = useState(false);
  const [license, setLicense] = useState(false);
  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(1024);
  const [steps, setSteps] = useState(20);
  const [batchSize, setbatchSize] = useState(1);
  const [seed, setseed] = useState(1);
  const [prompt, setPrompt] = useState('');
  const [nprompt, setNPrompt] = useState('');
  const [cfgValue, setCfgValue] = useState(7);
  const [sampler, setSampler] = useState('');
  const [hires, setHires] = useState(false);
  const [refiner, setRefiner] = useState(false);
  const [upscaleBy, setUpscaleBy] = useState(1.5);
  const [refinerSteps, setrefinerSteps] = useState(20);
  const [promptHistoryList, setpromptHistoryList] = useState([]);
  const [promptId, setpromptId] = useState('');
  const [batchStatus, setbatchStatus] = useState(1);
  const [loraThumbsList, setLoraThumbsList] = useState({});
  const [productGeneration, setproductGeneration] = useState(false);
  const [productsCount, setproductsCount] = useState(0);
  const [downloadImages, setdownloadImages] = useState(false);
  const [quickLoraSelect, setquickLoraSelect] = useState(false);
  const [hiresGeneration, sethiresGeneration] = useState(false);
  const [hiresJobs, setHiresJobs] = useState([]);
  const [showBackdrop, setshowBackdrop] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    category: [
      'Photo-unreal - with stuff like travels, weddings, history etc.',
      'Imagined - with stuff like fantasy, cartoons etc.',
    ],
    product: 'All',
    status: 'All',
  });

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [saveDialogData, setSaveDialogData] = useState();
  const [promptListDialogOpen, setPromptListDialogOpen] = useState(false);
  const [promptName, setPromptName] = useState('');
  const [categoriesTree, setCategoriesTree] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [promptToLoad, setPromptToLoad] = useState('');
  const [loadedPromptName, setloadedPromptName] = useState('');
  const [loadedPromptCategory, setLoadedPromptCategory] = useState();
  const [loadedPromptGroup, setLoadedPromptGroup] = useState();
  const [promptAuthor, setpromptAuthor] = useState('');
  const [promptThumb, setPromptThumb] = useState('');
  const [savePromptBlocked, setsavePromptBlocked] = useState(true);
  const [cachedPromptToLoad, setCachedPromptToLoad] = useState('');
  const [selectedSpecies, setSelectedSpecies] = useState('human');
  const [selectedGender, setSelectedGender] = useState('male');
  const [isProduction, setIsProduction] = useState('No');
  const [seedDialogOpen, setseedDialogOpen] = useState(false);
  const [seedDialogData, setseedDialogData] = useState();
  const [seedsList, setseedsList] = useState([]);
  const [hiresList, sethiresList] = useState([]);
  const [prompts, setprompts] = useState({
    human_male: '',
    human_female: '',
    cat_male: '',
    cat_female: '',
    dog_male: '',
    dog_female: '',
  });
  const [nprompts, setnprompts] = useState({
    human_male: '',
    human_female: '',
    cat_male: '',
    cat_female: '',
    dog_male: '',
    dog_female: '',
  });
  const [multiParams, setmultiParams] = useState({
    lorastrength: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    steps: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    cfg: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    refiner: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    refinersteps: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    sampler: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
  });
  const [selectedByQ, setselectedByQ] = useState(false);

  const value = {
    alert,
    setAlert,
    promptRef,
    npromptRef,
    isLoading,
    setisLoading,
    resultImage,
    setResultImage,
    resultSeeds,
    setResultSeeds,
    progress,
    setProgress,
    checkpoints,
    setcheckpoints,
    checkpoint,
    setCheckpoint,
    lorasList,
    setlorasList,
    lora,
    setLora,
    loraDialogOpen,
    setLoraDialogOpen,
    selectedLoras,
    setSelectedLoras,
    selectedLorasCount,
    setselectedLorasCount,
    loraStrength,
    setLoraStrength,
    sdVersion,
    setSdVersion,
    versionSwitch,
    setVersionSwitch,
    license,
    setLicense,
    width,
    setWidth,
    height,
    setHeight,
    steps,
    setSteps,
    batchSize,
    setbatchSize,
    seed,
    setseed,
    prompt,
    setPrompt,
    nprompt,
    setNPrompt,
    cfgValue,
    setCfgValue,
    sampler,
    setSampler,
    hires,
    setHires,
    refiner,
    setRefiner,
    upscaleBy,
    setUpscaleBy,
    refinerSteps,
    setrefinerSteps,
    promptHistoryList,
    setpromptHistoryList,
    promptId,
    setpromptId,
    batchStatus,
    setbatchStatus,
    loraThumbsList,
    setLoraThumbsList,
    productGeneration,
    setproductGeneration,
    productsCount,
    setproductsCount,
    downloadImages,
    setdownloadImages,
    quickLoraSelect,
    setquickLoraSelect,
    hiresGeneration,
    sethiresGeneration,
    hiresJobs,
    setHiresJobs,
    showBackdrop,
    setshowBackdrop,
    appliedFilters,
    setAppliedFilters,
    saveDialogOpen,
    setSaveDialogOpen,
    saveDialogData,
    setSaveDialogData,
    promptListDialogOpen,
    setPromptListDialogOpen,
    promptName,
    setPromptName,
    categoriesTree,
    setCategoriesTree,
    categories,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    groups,
    setGroups,
    selectedGroup,
    setSelectedGroup,
    newGroupName,
    setNewGroupName,
    promptToLoad,
    setPromptToLoad,
    loadedPromptName,
    setloadedPromptName,
    loadedPromptCategory,
    setLoadedPromptCategory,
    loadedPromptGroup,
    setLoadedPromptGroup,
    promptAuthor,
    setpromptAuthor,
    promptThumb,
    setPromptThumb,
    savePromptBlocked,
    setsavePromptBlocked,
    cachedPromptToLoad,
    setCachedPromptToLoad,
    selectedSpecies,
    setSelectedSpecies,
    selectedGender,
    setSelectedGender,
    isProduction,
    setIsProduction,
    seedDialogOpen,
    setseedDialogOpen,
    seedDialogData,
    setseedDialogData,
    seedsList,
    setseedsList,
    hiresList,
    sethiresList,
    prompts,
    setprompts,
    nprompts,
    setnprompts,
    multiParams,
    setmultiParams,
    selectedByQ,
    setselectedByQ,
  };
  return (
    <GenerateContext.Provider value={value}>
      {children}
    </GenerateContext.Provider>
  );
}
