import { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import { TextField } from '@mui/material';
import SmallDialog from 'components/SmallDialog';

export default function ContextMenu({
  contextMenu,
  handleClose,
  copyPath,
  handleTopUp,
  handleActivateAccount,
  handleChangeAdminStatus,
  test,
}) {
  const [open, setopen] = useState(false);
  const [data, setdata] = useState();

  const balanceAmount = useRef();

  useEffect(() => {
    if (data) {
      console.log('test topup');
      handleTopUp(parseInt(balanceAmount.current.value));
      setdata(null);
    }
  }, [data]);

  return (
    <>
      <Paper sx={{ width: 320 }}>
        <Menu
          id="basic-menu"
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          open={contextMenu !== null}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        >
          <MenuItem>
            <ListItemText onClick={handleActivateAccount}>
              Activate
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={handleChangeAdminStatus}>Admin</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={() => copyPath(true)}>
              Copy path to user generations
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={() => copyPath(false)}>
              Copy path to user LORAs
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={() => setopen(true)}>
              Top up balance
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={test}>est</ListItemText>
          </MenuItem>
        </Menu>
      </Paper>
      <SmallDialog
        title="Input amount"
        content=""
        open={open}
        setOpen={setopen}
        setData={setdata}
        confirmButton="top up"
        cancelButton="cancel"
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="balanceAmount"
          label="amount"
          name="balanceAmount"
          autoComplete="100"
          inputRef={balanceAmount}
          multiline={false}
          autoFocus
          className="ring-0"
        />
      </SmallDialog>
    </>
  );
}
