import { Box, useTheme, IconButton } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import React from 'react';
import BasicMenu from 'components/Menu';

import { useDispatch, useSelector } from 'react-redux';

import { toggleQueueDrawer } from 'state/generate';
import CheckpointSettings from './Settings/CheckpointSettings';

const MenuBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const prompt_items = [
    {
      text: 'New',
      func: () => {
        console.log('New');
      },
    },
    {
      text: 'Open',
      func: () => {
        console.log('Open');
      },
    },
    {
      text: 'Save',
      func: () => {
        console.log('Save');
      },
    },
    {
      text: 'Save as',
      func: () => {
        console.log('Save as');
      },
    },
  ];
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="20px"
        height="44px"
        padding="5px 50px"
        marginTop="25px"
        backgroundColor={theme.palette.yellows.main}
      >
        {/* <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <BasicMenu title="prompt" items={prompt_items} />
          </Box>
          <Box paddingLeft="20px">
            <CheckpointSettings />
          </Box>
        </Box> */}
        <Box></Box>
        <IconButton
          onClick={() => {
            dispatch(toggleQueueDrawer(true));
          }}
          sx={{
            alignItems: 'flex-end',
            color: theme.palette.primary.main,
            ml: '0',
          }}
        >
          <ArrowBackIos />
        </IconButton>
      </Box>
    </>
  );
};

export default MenuBar;
