import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonGroup } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { AddAPhoto, Delete } from '@mui/icons-material';

import {
  useGetProductImagesMutation,
  useSetProductImagesMutation,
  useDeleteProductImageMutation,
  useModifyImageTypeMutation,
} from 'api/apiSlice';

export default function ProductImages({ name }) {
  const [open, setOpen] = useState(false);
  const [files, setfiles] = useState([]);
  const [images, setImages] = useState([]);
  const [thumb, setthumb] = useState('');

  const [loading, setloading] = useState(false);
  const [currenttab, setcurrenttab] = useState(0);

  const [getProductImages, { isloading }] = useGetProductImagesMutation();
  const [setProductImages] = useSetProductImagesMutation();

  // load images from backend
  useEffect(() => {
    if (open) {
      setImages([]);
      setloading(true);

      getProductImages(name)
        .unwrap()
        .then((imgs) => {
          try {
            console.log(imgs);
            const samples = imgs.images.samples.map((sample, index) => {
              var item = {
                img: `https://devrest.garagefarm.net/api/v2/products/${name.replace(
                  ' ',
                  ''
                )}/${sample.img}`, //sample.img,
                species: sample.species,
                gender: sample.gender,
                thumb: false,
                uuid: sample.img,
              };
              return item;
            });
            const thumbs = imgs.images.thumb.map((thumb) => {
              var item = {
                img: `https://devrest.garagefarm.net/api/v2/thumb/${name.replace(
                  ' ',
                  ''
                )}/${thumb.img}`,
                species: thumb.species,
                gender: thumb.gender,
                thumb: true,
                uuid: thumb.img,
              };
              return item;
            });
            // if (imgs.data.images[0].images.thumb.length !== 0) {
            //   const thumb = { ...imgs.data.images[0].images.thumb[0] };
            //   setImages([thumb, ...samples]);
            // } else {
            //   setImages([...samples]);
            // }
            console.log(samples);
            setImages([...thumbs, ...samples]);
            setloading(false);
          } catch (e) {
            setloading(false);
            console.log('error obtaining product images', e);
          }
        });
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    // const samples = images.filter((img) => {
    //   return img.thumb === false;
    // });
    // const thumb = images.filter((img) => {
    //   return img.thumb === true;
    // });

    const formData = new FormData();
    formData.append('name', name);
    var s_data = [];
    var t_data = [];
    var files_count = 0;
    images.forEach((item, index) => {
      if (item.hasOwnProperty('file')) {
        if (!item.thumb) {
          // samples.push(item.file);
          formData.append('samples', item.file);
          s_data.push({
            gender: item.gender,
            species: item.species,
          });
        } else {
          // thumbs.push(item.file);
          formData.append('thumb', item.file);
          t_data.push({
            gender: item.gender,
            species: item.species,
          });
        }
        files_count += 1;
      }
    });

    formData.append('s_data', JSON.stringify(s_data));
    formData.append('t_data', JSON.stringify(t_data));
    if (files_count > 0) {
      setloading(true);
      setProductImages(formData)
        .unwrap()
        .then((resp) => {
          setloading(false);
          console.log('sent');
        });
    }
  };

  const handleTabChange = (event, newValue) => {
    setcurrenttab(newValue);
  };

  const tabs = [
    { value: 0, name: 'default', species: 'default', gender: 'default' },
    { value: 1, name: 'human male', species: 'human', gender: 'male' },
    { value: 2, name: 'human female', species: 'human', gender: 'female' },
    { value: 3, name: 'cat male', species: 'cat', gender: 'male' },
    { value: 4, name: 'cat female', species: 'cat', gender: 'female' },
    { value: 5, name: 'dog male', species: 'dog', gender: 'male' },
    { value: 6, name: 'dog female', species: 'dog', gender: 'female' },
  ];

  return (
    <>
      <div onClick={handleClickOpen}>
        <AddAPhoto />
        Add images
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Tabs
            value={currenttab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {tabs.map((tab) => {
              return <Tab label={tab.name} value={tab.value} />;
            })}
          </Tabs>
          {tabs.map((tab, index) => {
            return (
              <SingleTab
                gender={tab.gender}
                species={tab.species}
                loading={loading}
                setloading={setloading}
                images={images}
                setImages={setImages}
                value={currenttab}
                index={index}
                name={name}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            onClick={handleClose}
            sx={{ margin: '10px' }}
          >
            CANCEL
          </Button>
          <Button variant="filled" onClick={handleSave} sx={{ margin: '10px' }}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SingleTab({
  gender,
  species,
  loading,
  setloading,
  images,
  setImages,
  value,
  index,
  name,
}) {
  const handleUpload = (files) => {
    const f = [...files];
    setloading(true);
    f.map((file) => {
      const reader = new FileReader();
      var item = {
        img: '',
        file: file,
        species: species,
        gender: gender,
        thumb: false,
      };
      reader.onload = (e) => {
        item.img = e.target.result;
        setImages((current) => [...current, item]);
      };
      reader.readAsDataURL(file);

      return true;
    });
    setloading(false);
  };
  return (
    <>
      {value === index && (
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        >
          <Box
            sx={{
              mt: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <input
              type="file"
              onChange={(e) => handleUpload(e.target.files)}
              multiple
              accept="image/*"
            />
          </Box>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            images.length > 0 && (
              <Gallery
                images={images}
                setImages={setImages}
                species={species}
                gender={gender}
                name={name}
              />
            )
          )}
        </Box>
      )}
    </>
  );
}

function Gallery({ images, setImages, species, gender, name }) {
  const [deleteProductImage] = useDeleteProductImageMutation();
  const [changeImageType] = useModifyImageTypeMutation();
  const handleDelete = (idx) => {
    var temp = [...images];
    temp.splice(idx, 1);
    setImages(temp);
    if (!images[idx].hasOwnProperty('file')) {
      deleteProductImage({
        name: name,
        type: images[idx].thumb ? 'thumb' : 'samples',
        filename: images[idx].uuid,
      });
    }
  };

  const handlesethumb = (idx) => {
    var temp = [...images];
    var isThumb = images[idx].thumb ? true : false;
    changeImageType({
      name: name,
      filename: images[idx].img.split('/').pop(),
      isThumb: isThumb,
    });
    setImages(
      temp.map((img, index) => {
        return index === idx ? { ...img, thumb: !img.thumb } : { ...img };
      })
    );
  };

  return (
    <>
      <ImageList
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
          paddingTop: '50px',
        }}
        cols={4}
        rowHeight={300}
        gap={20}
      >
        {images.map((item, idx) => {
          if (item.species === species && item.gender === gender) {
            return (
              <ImageListItem key={idx} sx={{}}>
                <Box>
                  {item.thumb && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        left: '-20px',
                        backgroundColor: '#fecd27',
                        transform: 'rotate(-45deg)',
                        color: '#000',
                        padding: '0px 20px',
                      }}
                    >
                      THUMB
                    </div>
                  )}
                  <img src={item.img} alt="" width="300" key={idx} />
                  <ImageListItemBar
                    title={item.species}
                    subtitle={item.gender}
                    actionIcon={
                      <ButtonGroup>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255)' }}
                          onClick={() => handlesethumb(idx)}
                        >
                          <AddAPhoto />
                        </IconButton>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255)' }}
                          onClick={() => handleDelete(idx)}
                        >
                          <Delete />
                        </IconButton>
                      </ButtonGroup>
                    }
                  />
                </Box>
              </ImageListItem>
            );
          } else {
            return <></>;
          }
        })}
      </ImageList>
    </>
  );
}
