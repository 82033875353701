import { useEffect, useState } from 'react';

import SimpleAccordion from 'components/Accordian';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  ListItemText,
} from '@mui/material';
import LoraDialog from 'scenes/lora/LoraDialog';
import { makeid } from 'utils/globalUtils';
import { useGenerate } from '../Context';
import useCheckpointSettings from '../hooks/useCheckpointSettings';

import { useSelector, useDispatch } from 'react-redux';
import {
  checkpoints as checkpointsList,
  loras,
  setLoraDialogOpen,
  setLoraStrength,
  setSelectedCheckpoint,
  setSelectedFluxLoras,
} from 'state/generate';

const CheckpointSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const quickLoraSelect = useSelector(
    (state) => state.generate.quickLoraSelect
  );
  const loraStrength = useSelector((state) => state.generate.loraStrength);
  const lorasList = useSelector((state) => state.generate.loras);
  const selectedLoras = useSelector((state) => state.generate.selectedLoras);
  const loraDialogOpen = useSelector((state) => state.generate.lorasDialogOpen);
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const fluxLoras = useSelector((state) => state.generate.flux_loras);
  const selectedFluxLoras = useSelector(
    (state) => state.generate.selected_flux_loras
  );
  const {
    handleLoraClose,
    handleLoraOpen,
    countSelected,
    handleQuickLoraSelect,
    handleQuickLora,
  } = useCheckpointSettings();

  const checkpoints = useSelector(checkpointsList);
  const checkpoint = useSelector((state) => state.generate.selectedCheckpoint);
  const g1 = theme.palette.primary[100];

  const handleSelectFluxLora = (value) => {
    console.log(value);
    dispatch(
      setSelectedFluxLoras(typeof value === 'string' ? value.split(',') : value)
    );
  };

  const ITEM_HEIGHT = 38;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 300,
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel sx={{ color: g1 }} id="demo-simple-select-label">
            checkpoint
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="checkpointSelect"
            inputProps={{ id: () => makeid(5) }}
            value={checkpoint}
            label="Checkpoint"
            onChange={(event) =>
              dispatch(setSelectedCheckpoint(event.target.value))
            }
            labelStyle={{ color: '#000' }}
            sx={{
              height: '40px',
            }}
          >
            {checkpoints.map((model, index) => {
              return (
                <MenuItem key={index} value={model.model_name}>
                  {model.model_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* to delete  */}

        {/* {checkpoint === 'flux_dev' || checkpoint === 'flux_schnell' ? (
          <>
            <FormControl sx={{ minWidth: 120, width: 300 }}>
              <InputLabel sx={{ color: g1 }} id="demo-simple-select-label">
                loras
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="checkpointSelect"
                inputProps={{ id: () => makeid(5) }}
                value={selectedFluxLoras}
                label="lora"
                onChange={(event) => handleSelectFluxLora(event.target.value)}
                labelStyle={{ color: '#000' }}
                sx={{
                  height: '40px',
                }}
                renderValue={() =>
                  selectedFluxLoras.filter((item) => item !== null).length
                }
                multiple
                MenuProps={MenuProps}
              >
                {fluxLoras.map((model, index) => {
                  if (model.split('.')[1] === 'safetensors') {
                    return (
                      <MenuItem key={index} value={model.split('.')[0]}>
                        <Checkbox
                          checked={
                            selectedFluxLoras.indexOf(model.split('.')[0]) > -1
                          }
                        />
                        <ListItemText primary={model.split('.')[0]} />
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <Button variant="contained" onClick={handleLoraOpen}>
              LORAs
            </Button>
            <LoraDialog
              lorasList={lorasList}
              onClose={handleLoraClose}
              loraStrength={
                loadedPrompt.params.lorastrength[
                  `${loadedPrompt.species}_${loadedPrompt.gender}`
                ]
              }
            />
            <Box
              sx={{
                display: 'flex',

                justifyContent: 'center',
              }}
            >
              <FormGroup sx={{ paddingLeft: '10px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="quickLoraSelect"
                      checked={quickLoraSelect}
                      onChange={(event) => handleQuickLora(event)}
                    />
                  }
                  label="Quick lora select"
                  sx={{ color: g1 }}
                />
              </FormGroup>
            </Box>
          </>
        )} */}
        <>
          <Button variant="contained" onClick={handleLoraOpen}>
            LORAs
          </Button>
          <LoraDialog
            lorasList={lorasList}
            onClose={handleLoraClose}
            loraStrength={
              loadedPrompt.params.lorastrength[
                `${loadedPrompt.species}_${loadedPrompt.gender}`
              ]
            }
          />
          <Box
            sx={{
              display: 'flex',

              justifyContent: 'center',
            }}
          >
            <FormGroup sx={{ paddingLeft: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="quickLoraSelect"
                    checked={quickLoraSelect}
                    onChange={(event) => handleQuickLora(event)}
                  />
                }
                label="Quick lora select"
                sx={{ color: g1 }}
              />
            </FormGroup>
          </Box>
        </>
      </Box>
    </>
    // </SimpleAccordion>
  );
};

export default CheckpointSettings;
