import { useState, useEffect, useRef } from 'react';

import { useGenerate } from '../Context';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedLoras,
  setLoraDialogOpen,
  setLoraStrength,
  setSelectedLorasCount,
  setQuickLoraSelect,
  setLoras,
} from 'state/generate';

const useCheckpointSettings = () => {
  const dispatch = useDispatch();
  const quickLoraSelect = useSelector(
    (state) => state.generate.quickLoraSelect
  );
  const selectedLoras = useSelector((state) => state.generate.selectedLoras);
  const loraStrength = useSelector((state) => state.generate.loraStrength);
  const lorasList = useSelector((state) => state.generate.loras);
  const loadedPrompt = useSelector((state) => state.generate.loadedPrompt);
  const { selectedByQ, setselectedByQ } = useGenerate();

  const prevQuickLoraSelectRef = useRef();
  const prevSpeciesRef = useRef();
  const prevGenderRef = useRef();

  const handleLoraOpen = () => {
    console.log('open');
    dispatch(setLoraDialogOpen(true));
  };

  const handleLoraClose = (value, strength) => {
    console.log(value);
    dispatch(setLoraDialogOpen(false));
    dispatch(setSelectedLoras(value));
    countSelected(value);
    dispatch(setLoraStrength(strength));
  };

  const countSelected = (value) => {
    var selected = { male: 0, female: 0, cat: 0, dog: 0 };
    value.forEach((l) => {
      if (l.selected) {
        selected[l.sex] += 1;
      }
    });
    dispatch(setSelectedLorasCount(selected));
  };

  const handleQuickLoraSelect = () => {
    console.log(`quick lora checkbox ${quickLoraSelect}`);
    const sg =
      loadedPrompt.species === 'human'
        ? loadedPrompt.gender
        : loadedPrompt.species;
    if (quickLoraSelect) {
      console.log('on');
      // const filtered = lorasList
      //   .filter((lora) => lora.user === 'adam.kosiorek' && lora.sex === sg)
      //   .map((l) => ({ ...l, selected: true, strength: loraStrength }));
      const filtered = lorasList.map((l) => {
        if (l.user === 'adam.kosiorek' && l.sex === sg) {
          return { ...l, selected: true, strength: loraStrength };
        } else {
          return { ...l, selected: false, strength: loraStrength };
        }
      });
      dispatch(setLoras(filtered));
      console.log(filtered.filter((l) => l.selected).length);
      const concated = selectedLoras.concat(filtered);
      countSelected(filtered.filter((l) => l.selected));
      // dispatch(setSelectedLoras(filtered));
    } else {
      if (prevQuickLoraSelectRef.current !== quickLoraSelect) {
        console.log('off');
        countSelected([]);
        dispatch(setSelectedLoras([]));
        dispatch(setLoras(lorasList.map((l) => ({ ...l, selected: false }))));
        console.log('quickLoraSelect changed');
      }
    }
  };
  const handleQuickLora = (event) => {
    dispatch(setQuickLoraSelect(event.target.checked));
  };

  useEffect(() => {
    // Call functions when dependencies change
    handleQuickLoraSelect();
    setselectedByQ(quickLoraSelect);

    // Update previous values to current
    prevQuickLoraSelectRef.current = quickLoraSelect;
    prevSpeciesRef.current = loadedPrompt.species;
    prevGenderRef.current = loadedPrompt.gender;
  }, [quickLoraSelect, loadedPrompt.species, loadedPrompt.gender]);

  return {
    handleLoraClose,
    handleLoraOpen,
    countSelected,
    handleQuickLoraSelect,
    handleQuickLora,
  };
};

export default useCheckpointSettings;
