export const ordersEndpoints = (builder) => {
  return {
    getSingleOrder: builder.mutation({
      query: (uuid) => ({
        url: `api/v2/user/orders/${uuid}`,
        method: 'get',
      }),
    }),
    updateOrder: builder.mutation({
      query: (data) => ({
        url: `api/v2/user/orders/${data.uuid}`,
        method: 'post',
        body: data,
      }),
    }),
    
  };
};
