import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import global from 'state/global';
import auth from 'state/auth';
import generate from 'state/generate';
import { apiSlice } from 'api/apiSlice';

import { AuthProvider } from 'utils/Auth';
import { CmsProvider } from 'utils/CMS';
import { GenerateProvider } from 'scenes/generate/Context';

const persistConfig = {
  key: 'root',
  storage,
  // Optionally, you can whitelist specific reducers to be persisted
  whitelist: ['authentication', 'global', 'generate'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    global,
    authentication: auth,
    generate: generate,
    [apiSlice.reducerPath]: apiSlice.reducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <CmsProvider>
            <GenerateProvider>
              <App />
            </GenerateProvider>
          </CmsProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
