import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';

import ContextMenu from './ContextMenu';
import Compare from './Compare';
import Dropdown from 'components/Dropdown';
import { Progress } from 'components/SmallComponents';

import { formatDate } from 'utils';
import { useGetReportsMutation, useUpdateReportMutation } from 'api/apiSlice';
import { columns } from './columns';

export default function DatagridReports() {
  const apiRef = useGridApiRef();
  const [getReports, { isLoading }] = useGetReportsMutation();
  const [updateReport, { isUpdating }] = useUpdateReportMutation();

  const [rows, setrows] = useState([]);
  const [compareDialogOpen, setCompareDialogOpen] = useState(false);
  const [compareImages, setcompareImages] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    async function gr() {
      const reports = await getReports().unwrap();
      const rr = reports.reports.map((r, index) => {
        var temp = { ...r };
        temp.feedback = r.feedback.message;
        temp.type = r.feedback.type;
        temp.user = r.user.email;
        temp.id = index;
        temp.reported_at = formatDate(r.reported_at);
        temp.generated_at = formatDate(r.generated_at);
        temp.index = r.image;
        temp.image = `https://devrest.garagefarm.net/images/${r.job}/${r.image}?user=${r.user.uuid}`;

        return temp;
      });
      setrows(rr);
    }
    if (rows.length === 0) {
      gr();
    }
  }, []);

  useEffect(() => {
    rows.length !== 0 && setloading(false);
  }, [rows]);

  const getRowHeight = (params) => {
    const lineHeight = 20; // Adjust this value based on your font size and styling
    const lines = Math.ceil(params.model.feedback.length / 50); // Adjust the number of characters per line as needed
    var h = lines * lineHeight + 16;
    if (h < 100) {
      return 100;
    } else {
      return h;
    }
  };

  const handleRowUpdate = () => {
    console.log('handleRowUpdate');
  };
  const handleOnCellClick = (params) => {
    if (params.field === 'image') {
      window.open(params.value, '_blank', 'noreferrer');
    }
  };

  const [contextMenu, setContextMenu] = useState(null);
  const [rowClicked, setrowClicked] = useState(null);
  const handleRowContextMenu = (event) => {
    event.preventDefault();
    const rowId = Number(event.currentTarget.getAttribute('data-id'));
    setrowClicked(rowId);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const markAsCompleted = async () => {
    const record = rows.find((row) => row.id === rowClicked);
    console.log(apiRef.current.getRow(rowClicked));
    const r = apiRef.current.getRow(rowClicked);
    if (!r.reviewed) {
      const resp = await updateReport({
        query: {
          q: { uuid: record.uuid },
          s: { reviewed: true },
        },
      }).unwrap();
      if (resp.status === 'success') {
        apiRef.current.updateRows([{ id: rowClicked, reviewed: true }]);
      }
    } else {
      const resp = await updateReport({
        query: {
          q: { uuid: record.uuid },
          s: { reviewed: false },
        },
      }).unwrap();
      if (resp.status === 'success') {
        apiRef.current.updateRows([{ id: rowClicked, reviewed: false }]);
      }
    }
  };
  const copyPath = (type) => {
    const record = rows.find((row) => row.id === rowClicked);
    console.log(record);
    var path = '';
    if (type === 'Generation') {
      path = `u:\\a0000009\\Gleem\\Generation\\${record.image.split('=')[1]}\\${
        record.job
      }\\output`;
    } else {
      path = `u:\\a0000009\\Gleem\\LORAs\\${record.image.split('=')[1]}\\${
        record.lora
      }\\training_data\\img`;
    }

    navigator.clipboard.writeText(path);
  };

  const openCompareDialog = () => {
    const r = apiRef.current.getRow(rowClicked);
    console.log(r);
    const loraImage = `https://devrest.garagefarm.net/${
      r.image.split('=')[1]
    }/avatar/${r.lora}`;
    const generationImage = r.image;
    setcompareImages([loraImage, generationImage]);
    setCompareDialogOpen(true);
  };

  return (
    <>
      <Box sx={{ height: '90vh', width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            row: {
              onContextMenu: handleRowContextMenu,
              style: { cursor: 'context-menu' },
            },
            loadingOverlay: Progress,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            filter: {
              filterModel: {
                items: [
                  {
                    id: 1,
                    field: 'reviewed',
                    value: 'false',
                    operator: 'is',
                  },
                ],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: {
                index: false,
                job: false,
                order: false,
                generated_at: false,
                lora: false,
              },
            },
          }}
          getRowHeight={getRowHeight}
          pageSizeOptions={[50]}
          disableDensitySelector
          onCellClick={handleOnCellClick}
          loading={loading}
        />
      </Box>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        markAsCompleted={markAsCompleted}
        copyPath={copyPath}
        openCompareDialog={openCompareDialog}
      />
      <Compare
        images={compareImages}
        open={compareDialogOpen}
        setOpen={setCompareDialogOpen}
      />
    </>
  );
}

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarQuickFilter />
//       <GridToolbarColumnsButton sx={{ color: '#fff' }} />
//       <GridToolbarFilterButton sx={{ color: '#fff' }} />
//       <GridToolbarDensitySelector sx={{ color: '#fff' }} />
//       <GridToolbarExport sx={{ color: '#fff' }} />
//     </GridToolbarContainer>
//   );
// }
