import { useState, useRef } from 'react';

import { Box, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import DataGridUsers from './DataGridUsers';

const Users = () => {
  const emailRef = useRef();
  const email2Ref = useRef();
  const amountRef = useRef();
  const [inviteCode, setinviteCode] = useState('');

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const handleGenerate = () => {
    const code = Math.random().toString(36).slice(2, 10);

    var ms = new Date().getTime() + 86400000 * 7;
    const validTo = new Date(ms);
    // createInvite(emailRef.current.value, code, validTo, username).then(
    //   (resp) => {
    //     if (resp.success) {
    //       setAlert({
    //         show: true,
    //         severity: 'success',
    //         msg: `Invite code succesfully created for email ${emailRef.current.value}. Invite code will be valid for 48h`,
    //       });
    //       setinviteCode(code);
    //     } else {
    //       setAlert({
    //         show: true,
    //         severity: 'error',
    //         msg: 'Error creating invite',
    //       });
    //     }
    //   }
    // );
  };

  return (
    <>
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          border: '1px solid #fff',
          borderRadius: '10px',
          padding: '10px 20px',
          marginBottom: '20px',
        }}
      >
        <Typography sx={{ paddingTop: '5px', color: '#fff' }}>
          Generate invite code
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <TextField
            margin="normal"
            id="email"
            label="email"
            name="email"
            className="ring-0"
            sx={{ width: '240px' }}
            size="small"
            inputRef={emailRef}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, width: '100px' }}
            onClick={handleGenerate}
          >
            GENERATE
          </Button>
        </Box>
        {inviteCode !== '' && (
          <Typography
            sx={{
              fontSize: '20px',
              border: '1px solid #ff6000',
              borderRadius: '5px',
              padding: '10px 40px',
            }}
          >
            {inviteCode}
          </Typography>
        )}
      </Box> */}
      <DataGridUsers />
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '100px',
        }}
      >
        <Typography>Add credits</Typography>
        <TextField
          margin="normal"
          id="email"
          label="email"
          name="email"
          className="ring-0"
          sx={{ width: '240px' }}
          size="small"
          inputRef={email2Ref}
        />
        <TextField
          margin="normal"
          id="amount"
          label="amount"
          name="amount"
          className="ring-0"
          sx={{ width: '240px' }}
          size="small"
          inputRef={amountRef}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, width: '100px' }}
          onClick={handleTopUp}
        >
          ADD
        </Button>
      </Box> */}
    </>
  );
};

export default Users;
